/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState, useRef } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import Modal from "../../components/Modal/Modal"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import FloatingSelect from "../../components/Elements/Form/FloatingSelect"
import FloatingDateRange from "../../components/Elements/Form/FloatingDateRange"
import LayoutDashborad from "../../components/Layout/Dashboard"
import Pagination from "../../components/Pagination"
import ManageUserTable from "../../components/Table/ManageUserTable"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import StateContext from "../../context/state/StateContext"
import {
  getSubUserListing,
  updateSubUserSingle,
} from "../../helpers/DrupalHelper"
import ManageUserForm from "../../components/Forms/ManageUserForm"
import Button from "../../components/Elements/Button"
import NotificationContext from "../../context/Notification/NotificationContext"
import DataContext from "../../context/Data/DataContext"
import Skeleton from "../../components/Skeleton"
import DeleteConfirm from "../../components/Forms/DeleteConfirm"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
import PageSubtitle from "../../components/PageSubtitle"
import FloatingInput from "../../components/Elements/Form/FloatingInput"

function ManageUsers() {
  dashboardTrail.push({ text: "Manage Users", link: "#" })
  const { toggleNotification } = useContext(NotificationContext)
  const { state } = useContext(StateContext)
  const { userType } = useContext(AuthenticationContext)
  const { token } = state
  const [list, setList] = useState(null)
  const [filteredList, setFilteredList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const { currentUserCompaniesAsOp } = useContext(DataContext).data

  const [company, setCompany] = useState(
    currentUserCompaniesAsOp ? currentUserCompaniesAsOp[0] : false
  )
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [deleteCurrentId, setDeleteCurrentId] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)

  const onEditClick = v => {
    setEditCurrent(v)
    setOpen(true)
  }
  const onDeleteClick = id => {
    setDeleteCurrentId(id)
    setOpen(true)
  }
  const closeDeleteModal = () => {
    setDeleteCurrentId(null)
    setOpen(false)
  }
  const onSave = (data, v) => {
    if (v === "added") {
      let newList = [...data]
      setList([...data])

      toggleNotification({ content: "User Added Sucessfully" })
    } else if (v === "edited") {
      console.log({ data })
      setList([...data])
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: "User Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed", error: true })
    }
  }
  useEffect(async () => {
    if (company && typeof company.value !== "undefined") {
      const data = await getSubUserListing(state.token, company.value)
      setList(data)
      setFilteredList(data)
    }
  }, [company])

  useEffect(async () => {
    if (currentUserCompaniesAsOp) {
      setCompany(currentUserCompaniesAsOp[0])
    }
  }, [currentUserCompaniesAsOp])

  let endDate = new Date()
  let startDate = new Date()
  startDate.setMonth(startDate.getMonth() - 3)

  const [dateRange, setDateRange] = useState([
    { startDate, key: "selection", endDate },
  ])
  const handleSearchInputChange = event => {
    const query = event.target.value
    setSearchQuery(query)
    let filter = filteredList?.filter(
      item =>
        item.first_name?.toLowerCase().includes(query.toLowerCase()) ||
        item.last_name?.toLowerCase().includes(query.toLowerCase()) ||
        item.email?.toLowerCase().includes(query.toLowerCase())
    )

    console.log({ filteredList, filter })
    setList(filter)
  }
  const handleAccessSelectChange = e => {
    let filter
    if (e) {
      filter = filteredList.filter(item => item.role === e.value)
      setList(filter)
    } else {
      setList(filteredList)
    }
  }

  const handleApproveSelectChange = e => {
    let filter
    if (e) {
      filter = filteredList.filter(item => item.approval_status === e.value)
      setList(filter)
    } else {
      setList(filteredList)
    }
  }
  const isInitialRender = useRef(true)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    let filter
    const startDate = new Date(dateRange[0].startDate)
    const endDate = new Date(dateRange[0].endDate)
    console.log({ startDate, endDate })
    if (dateRange) {
      filter = filteredList?.filter(item => {
        const [date, time] = item.created.split(" ")
        const [day, month, year] = date.split("/")
        const [hour, minute, second] = time.split(":")
        const isoDateString = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`
        const createdDate = new Date(Date.parse(isoDateString))
        console.log({ createdDate }, item.created)
        return createdDate >= startDate && createdDate <= endDate
      })

      setList(filter)
    } else {
      setList(filteredList)
    }
  }, [dateRange])

  if (!userType.brandManager && !userType.retailerManager)
    return <AccessDenied />

  //   const [pageNumber, setPageNumber] = useState(1)
  // const [totalPage, setTotalPage] = useState(1)
  // const [total, setTotal] = useState(1)
  // const [perPage, setPerPage] = useState(20)
  // useEffect(async () => {
  //   let total = await getMyListingPagination(
  //     state.token,
  //     "user_group",
  //     "default"
  //   )
  //   setTotal(total)

  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [])
  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [perPage])
  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [total])
  // const onPageChange = async v => {
  //   setPageNumber(v)
  //   const newPageData = await getMyListing(
  //     state.token,
  //     "user_group",
  //     "default",
  //     v
  //   )
  //   // console.log(newPageData, "pageBody")
  //   setList([...newPageData])
  //   // setTotal(newPageData.total)
  // }
  return (
    <LayoutDashborad metaTitle="Manage Users" trail={dashboardTrail}>
      <div
        className={`mb-8 pb-6 ${!userType.brandManager && "lg:mt-20 md:mt-20"}`}
      >
        <div className="lg:w-1/2 lg:pr-8">
          <PageSubtitle title="Add and edit the users who have access to your company." />
        </div>
        <div className="mb-[30px]">
          <Button red widthAuto onClick={() => setOpenCreate(true)}>
            Add User
          </Button>
        </div>

        {currentUserCompaniesAsOp &&
          Array.isArray(currentUserCompaniesAsOp) &&
          currentUserCompaniesAsOp.length > 0 && (
            <FloatingSelect
              options={currentUserCompaniesAsOp}
              defaultValue={currentUserCompaniesAsOp[0]}
              label={"Your Company"}
              onChange={v => setCompany(v)}
              required
            />
          )}
        <div className="grid grid-cols-1 gap-4  sm:grid-cols-4">
          <FloatingInput
            label="Search User"
            name="user"
            onChange={e => {
              handleSearchInputChange(e)
            }}
          />
          <FloatingSelect
            options={[
              { value: "lead", label: "Manager" },
              { value: "default", label: "Employee" },
            ]}
            label={"Select Access Level"}
            onChange={e => handleAccessSelectChange(e)}
          />
          <FloatingSelect
            options={[
              { value: "approved", label: "Approved" },
              { value: "pending", label: "Pending" },
              { value: "denied", label: "Denied" },
            ]}
            label={"Select Status"}
            onChange={e => handleApproveSelectChange(e)}
          />
          <FloatingDateRange
            name="range"
            label="Date Range"
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </div>
        {list === null ? (
          <Skeleton table />
        ) : list.length > 0 ? (
          <ManageUserTable
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            data={list}
            company={company}
          />
        ) : (
          "No items to display"
        )}

        <Pagination page={1} setPage={v => console.log(v)} totalPages={0} />
        {/* <SectionCard title={"Create New User"} id="create">
        <ManageUserForm onSave={onSave} />
      </SectionCard> */}

        <Modal
          title={"Create New User"}
          isOpen={openCreate}
          closeModal={() => {
            setOpenCreate(false)
          }}
        >
          <ManageUserForm
            onSave={onSave}
            token={state.token}
            companyId={company?.value}
            companyName={company?.label}
            setOpenCreate={setOpenCreate}
          />
        </Modal>
        {open && editCurrent && (
          <Modal
            title={"Edit User"}
            isOpen={open}
            closeModal={() => {
              setEditCurrent(null)
              setOpen(false)
            }}
          >
            <ManageUserForm
              edit
              id={editCurrent}
              list={list}
              onSave={onSave}
              companyId={company?.value}
              companyName={company?.label}
              token={state.token}
              setOpen={setOpen}
            />
          </Modal>
        )}
        {open && deleteCurrentId && (
          <Modal
            isOpen={open}
            closeModal={closeDeleteModal}
            title={"Delete user"}
          >
            <DeleteConfirm
              onDelete={async () => {
                // console.log("delete", deleteCurrentId)

                let res = await updateSubUserSingle(
                  token,
                  deleteCurrentId,
                  company.value,
                  {
                    delete: true,
                  }
                )
                if (res.status === 200) {
                  res = await res.json()
                  toggleNotification({ content: res.message })
                  const data = await getSubUserListing(
                    state.token,
                    company.value
                  )
                  setList(data)
                } else {
                  toggleNotification({
                    content: "Sorry, there was an error.",
                    error: true,
                  })
                }
                closeDeleteModal()
              }}
              onCancel={closeDeleteModal}
            />
          </Modal>
        )}
        <Modal
          title={"Edit User"}
          isOpen={openAfterSave}
          closeModal={() => {
            setOpenAfterSave(false)
          }}
        >
          <h2>{contentAfterSave}</h2>
        </Modal>
      </div>
    </LayoutDashborad>
  )
}
export default ManageUsers
