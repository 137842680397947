/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"

import { useForm } from "react-hook-form"
import FloatingInput from "../Elements/Form/FloatingInput"
import FloatingSelect from "../Elements/Form/FloatingSelect"
import Button from "../Elements/Button"
import DataContext from "../../context/Data/DataContext"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import {
  addMySingleNewForm,
  addSubUser,
  addUserStore,
  checkEmailExist,
  getAllLocation,
  getMySingleForm,
  getSubUserListing,
  getSubUserSingle,
  getUserAddressBookListing,
  updateStoreSingle,
  updateSubUserSingle,
} from "../../helpers/DrupalHelper"
import BooleanType from "./DynamicForm/FormTypes/BooleanType"
import AsyncFloatingSelect from "../Elements/Form/AsyncFloatingSelect"
import FloatingTelephone from "../Elements/Form/FloatingTelephone"
import FloatingRadios from "../Elements/Form/FloatingRadios"
import StateContext from "../../context/state/StateContext"
import CustomLocationForm from "./CustomLocationForm"
import { validateEmail } from "../../utils/messageValidation"
import { CheckIcon } from "@heroicons/react/solid"
import Skeleton from "../Skeleton"
import DynamicForm from "./DynamicForm/DynamicForm"
import ButtonLite from "../Elements/ButtonLite"
// Reusable Form Component
// export const renderFields = (
//   data,
//   errors,
//   register,
//   setValue,
//   watch,
//   index,
//   unregister,
//   prename,
//   token,
//   control,
//   location
// ) => {
//   if (data) {
//     return Object.entries(data).map(field => {
//       let { type, label } = field[1]
//       let name = field[0]
//       // if (index) {
//       //   name = `${prename}_${field[0]}_${index.toString()}`;
//       // }
//       if (type === "text_long") {
//         type = "string_long"
//       }
//       switch (type) {
//         case "datetime":
//           return (
//             <DatePickerType
//               data={field[1]}
//               errors={errors}
//               setValue={setValue}
//               key={name}
//               name={name}
//               register={register}
//             />
//           )
//         default:
//           return (
//             <div key={name}>
//               <span className="text-red-400">Invalid field</span>
//             </div>
//           )
//       }
//     })
//   }
// }
function ManageUserForm({
  data,
  token,
  edit,
  id,
  companyId,
  onSave,
  list,
  setOpenCreate,
  setOpen,
  companyName,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    getValues,
    unregister,
    control,
    formState,
  } = useForm({ defaultValues: edit && list.filter(v => v.id === id)[0] })
  const { state, dispatch } = useContext(StateContext)

  const { userGroups } = useContext(DataContext).data
  const [fieldValue, setFieldValue] = useState()
  const [formFields, setFormFields] = useState()
  const [isDisabled, setisDisabled] = useState(true)
  const [storeCustomLoc, setStoreCustomLoc] = useState(false)
  const [storeLocations, setStoreLocation] = useState(null)
  const [existingEmailField, setExistingEmailField] = useState(true)
  const [emailExist, setEmailExist] = useState(null)
  const [allUserLocations, setAllUserLocations] = useState(null)
  const [locationsLoading, setLocationsLoading] = useState(true)
  const [editValues, setEditValues] = useState()
  const [formData, setFormData] = useState(null)
  const [userAddressFormData, setUserAddressFormData] = useState()
  const [suppData, setSuppData] = useState([])
  const [showAddressForm, setShowAddressForm] = useState(false)

  // const onCustomLocationSave = data => {
  //   let values = Object.values(data).filter(Boolean)
  //   let strAdd = values.toLocaleString().replaceAll(",", ", ")
  //   setValue("customBodyLocation", {
  //     name: "customBodyLocation",
  //     body: {
  //       entity_title: strAdd,
  //       json: {
  //         field_location_address: { ...data },
  //         field_location_shortname: strAdd,
  //       },
  //     },
  //   })
  // }
  // useEffect(() => {
  //   getAllStores(state.token).then(data => {
  //     const mappedLocations = Object.keys(data).map(key => ({
  //       value: data[key].location,
  //       label: data[key].location,
  //     }))
  //     setValue("address", mappedLocations[0].value)
  //     setStoreLocation(mappedLocations)
  //   })
  // }, [])

  console.log({ errors })
  console.log({ companyId })

  useEffect(async () => {
    let formData = await getMySingleForm(state.token, "address", "default")
    setFormData(formData)
    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
  }, [])

  useEffect(() => {
    if (edit) {
      const edit_value = list.filter(v => v.id === id)[0]
      setEditValues(edit_value)
      console.log(
        edit_value.companies.find(
          item => parseInt(item.company_id) === parseInt(companyId)
        ).company_id
      )
      setValue(
        "access_level",
        edit_value.companies.find(
          item => parseInt(item.company_id) === parseInt(companyId)
        ).association
      )
      setValue(
        "status",
        edit_value.companies.find(
          item => parseInt(item.company_id) === parseInt(companyId)
        ).status
      )
      setValue(
        "company",
        edit_value.companies
          .find(item => parseInt(item.company_id) === parseInt(companyId))
          .company_id.toString()
      )
      setValue("email_search", edit_value.email)
      // setValue("default_address", "4356")
    }
  }, [])
  console.log({ userAddressFormData })
  const onSubmit = async v => {
    const data = getValues()

    if (edit) {
      // console.log(list)
      // console.log(currentValue)
      let currentValue = list.filter(v => v.id === id)[0]
      let ModifiedObj = {}
      Object.keys(data).filter(key => {
        if (JSON.stringify(data[key]) !== JSON.stringify(currentValue[key])) {
          Object.assign(ModifiedObj, { [key]: data[key] })
          return { key: key, value: data[key] }
        }
      })
      let body = ModifiedObj
      if (userAddressFormData) {
        body.default_address = userAddressFormData
      }
      if (body.email_search) {
        body.email = body.email_search
        delete body.email_search
      }
      let res = await updateSubUserSingle(token, id, companyId, body)
      if (res.status === 200) {
        // let objIndex = list.findIndex(obj => obj.id == id)
        // let newList = list
        // newList[objIndex] = { ...newList[objIndex], ...ModifiedObj }
        // let nList = [...newList]
        // // console.log(nList)
        const data = await getSubUserListing(state.token, companyId)

        onSave(data, "edited")
      } else {
        onSave("Editing User", "failed")
      }
      setOpen(false)
    } else {
      let body = data
      if (userAddressFormData) {
        body.default_address = userAddressFormData
      }
      if (data.email_search && fieldValue) {
        body.email = formFields.email
        delete body.email_search
      }

      console.log({ body, data })
      let res = await addSubUser(token, body, companyId)
      setOpenCreate(false)
      if (res.status === 200) {
        const data = await getSubUserListing(state.token, companyId)

        onSave(data, "added")
      } else {
        onSave("Adding User", "failed")
      }
    }
  }
  useEffect(() => {
    register("access_level", { required: "This field is required" })
    register("company", { required: "This field is required" })
    register("status", { required: "This field is required" })
  }, [])
  useEffect(async () => {
    console.log(watch("email_search"))
    const temp_value =
      fieldValue &&
      fieldValue.filter(item => item.uid === watch("email_search"))[0]
    setFormFields(temp_value)
    console.log(temp_value)
    if (watch("company")) {
      const data = await getSubUserListing(state.token, watch("company"))
      const duplicate_email = data.find(
        item => item.email === temp_value?.email
      )
      register("email_search", {
        validate: value => {
          if (duplicate_email) {
            return "Please choose a different email address. A similar one is already in use with the selected company."
          } else return true
        },
      })
      console.log({ duplicate_email })
    }
    if (temp_value) {
      setValue("first_name", temp_value.first_name)
      setValue("last_name", temp_value.last_name)
      setValue("job_title", temp_value.job_title)
      temp_value.phone === null
        ? setValue("phone", "")
        : setValue("phone", temp_value.phone)
    }
  }, [watch("email_search"), watch("company")])

  useEffect(async () => {
    const all_locations = await getAllLocation(state.token)
    let ss = all_locations.map(item => {
      let obj = { value: item.id, label: item.title }
      return obj
    })
    setAllUserLocations(ss)
    setLocationsLoading(false)
  }, [])

  useEffect(() => {
    if (emailExist === true) {
      const timeout = setTimeout(() => {
        setExistingEmailField(true)
        setEmailExist(null)
        unregister("email")
        setisDisabled(true)
      }, 4000)

      return () => clearTimeout(timeout)
    }
  }, [emailExist, setExistingEmailField])

  // useEffect(async () => {
  //   if (watch("company")) {
  //     const data = await getSubUserListing(state.token, watch("company"))
  //     const duplicate_email = data.find(
  //       item => item.email === watch("email_search")
  //     )
  //     console.log({ duplicate_email })
  //   }
  //   console.log(watch("email_search"), watch("company"))
  // }, [watch("email_search"), watch("company")])
  return (
    <form
      onSubmit={handleSubmit(
        state.locformsubmit !== null ? state.locformsubmit(onSubmit) : onSubmit
      )}
    >
      {existingEmailField &&
        (edit ? (
          editValues && (
            <AsyncFloatingSelect
              label={"Email"}
              name="email_search"
              hookForm
              register={register}
              error={errors["email_search"]}
              rqMsg="Please select this field"
              userOptions
              control={control}
              notFoundButton={true}
              notFoundButtonTitle="Create new user"
              notFoundButtonF={() => {
                setisDisabled(false)
                setExistingEmailField(false)
                unregister("email_search")
              }}
              minChars={3}
              required
              setFieldValue={setFieldValue}
              showOnlyEmail
              defaultValue={{
                label: editValues.email,
                value: editValues.id,
              }}
              isDisabled={true}
            />
          )
        ) : (
          <AsyncFloatingSelect
            label={"Email"}
            name="email_search"
            hookForm
            register={register}
            error={errors["email_search"]}
            rqMsg="Please select this field"
            userOptions
            control={control}
            notFoundButton={true}
            notFoundButtonTitle="Create new user"
            notFoundButtonF={() => {
              setisDisabled(false)
              setExistingEmailField(false)
              unregister("email_search")
            }}
            minChars={3}
            required
            setFieldValue={setFieldValue}
            showOnlyEmail
          />
        ))}
      {!existingEmailField && (
        <div className="mb-5">
          <div className="flex relative mb-[-22px]">
            <FloatingInput
              label="Email Address"
              hookForm
              name="email"
              register={register}
              {...register("email", { required: "This field is required" })}
              error={
                emailExist
                  ? "A user with this email address already exists. Please search for and select this user from the list"
                  : errors["email"]
              }
              required
              disabled={isDisabled}
              onBlur={async e => {
                let email = e.target.value

                if (validateEmail(email)) {
                  let exists = await checkEmailExist(state.token, email)
                  setEmailExist(exists)
                }
              }}
            />
            {emailExist === false && (
              <div className="w-[20px] h-[20px] absolute top-[18px] right-[15px]">
                <CheckIcon color="green" />
              </div>
            )}
          </div>
          {emailExist !== null && emailExist && (
            <span className="text-red">
              A user with this email address already exists. Please search for
              and select this user from the list
            </span>
          )}
        </div>
      )}
      <div className="md:flex md:gap-x-[30px]">
        <FloatingInput
          label="First Name"
          hookForm
          name="first_name"
          register={register}
          error={errors["first_name"]}
          required={!existingEmailField ? true : false}
          disabled={isDisabled}
        />
        <FloatingInput
          label="Last Name"
          hookForm
          name="last_name"
          register={register}
          error={errors["last_name"]}
          disabled={isDisabled}
          required={!existingEmailField ? true : false}
        />
      </div>

      <FloatingInput
        label="Job Title"
        hookForm
        name="job_title"
        // type="email"
        register={register}
        error={errors["job_title"]}
        disabled={isDisabled}
        required={!existingEmailField ? true : false}
      />
      <FloatingTelephone
        label="Mobile Number (optional)"
        hookForm
        name="phone"
        register={register}
        error={errors["phone"]}
        setValue={setValue}
        control={control}
        type="tel"
        disabled={isDisabled}
        noValidation={existingEmailField ? true : false}
      />
      {edit && !showAddressForm && (
        <ButtonLite
          className="mb-4 z-[22]"
          inline
          pink
          sm
          bold
          type="button"
          callback={() => setShowAddressForm(true)}
        >
          {"Add address"}
        </ButtonLite>
      )}
      {(!edit || showAddressForm) && (
        <>
          {showAddressForm ? (
            <span>Add an Address for this user</span>
          ) : (
            <span>Add Default Address</span>
          )}
          <div className="border border-gray-200 rounded-lg p-4 mb-6 mt-2">
            {formData !== null && (
              <DynamicForm
                // onSave={onSave}
                addNew={true}
                data={formData}
                token={state.token}
                name="address"
                bundle="default"
                setUserAddressFormData={setUserAddressFormData}
                dynamicFields={[
                  {
                    subject: "field_store",
                    controller: "field_address_type",
                    if_equals: ["store"],
                  },
                  {
                    subject: "field_location",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                  {
                    subject: "field_first_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                  {
                    subject: "field_last_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                  {
                    subject: "field_company_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                ]}
                suppData={suppData}
              />
            )}
          </div>
        </>
      )}
      {
        // {!storeCustomLoc &&
        //   (locationsLoading ? (
        //     <h3 className="text-med-16 pr-2 flex items-center mb-5">
        //       <Skeleton dark width={"300px"} height={"30px"} />
        //     </h3>
        //   ) : (
        //     <FloatingSelect
        //       label="Default Address (optional)"
        //       name="default_address"
        //       options={allUserLocations}
        //       control={control}
        //       error={errors["default_address"]}
        //       rqMsg="Please choose your delivery location!"
        //       required
        //       noSelection={true}
        //       notFoundButton={true}
        //       notFoundButtonTitle="Can't find your Location?"
        //       notFoundButtonF={() => {
        //         setStoreCustomLoc(true)
        //         unregister("default_address")
        //       }}
        //       hookForm
        //     />
        //   ))}
        // {storeCustomLoc === true && (
        //   <CustomLocationForm onSave={onCustomLocationSave} />
        // )}
      }
      <FloatingSelect
        label={"Company*"}
        name="company"
        hookForm
        error={errors["company"]}
        register={register}
        type={"currentUserCompaniesAsOp"}
        control={control}
      />
      {edit && !editValues?.companies ? (
        <span>
          {` This user is not currently associated with ${companyName}. To
          associate them, assign an Access Level and Status below:`}
        </span>
      ) : null}

      <FloatingRadios
        label={"Access Level*"}
        name="access_level"
        flush
        required
        hookForm
        registerForm={register}
        error={errors["access_level"]}
        getValues={getValues}
        setValue={setValue}
        options={[
          {
            value: "lead",
            label: "Manager",
            checked: false,
          },
          {
            value: "default",
            label: "Associate/ Rep/ Other",
            checked: false,
          },
        ]}
      />

      <FloatingSelect
        label={"Status*"}
        name="status"
        hookForm
        register={register}
        options={[
          {
            value: "approved",
            label: "Approved",
          },
          {
            value: "denied",
            label: "Denied",
          },
          {
            value: "pending",
            label: "Pending",
          },
        ]}
        error={errors["status"]}
        control={control}
      />

      {
        //   <FloatingInput
        //   label="Sub-user email"
        //   hookForm
        //   name="email"
        //   type="email"
        //   register={register}
        //   error={errors["email"]}
        //   required
        //   disabled={edit ? true : false}
        // />
        // <FloatingInput
        //   label="Sub-user name"
        //   hookForm
        //   name="name"
        //   register={register}
        //   error={errors["name"]}
        //   required
        //   disabled={edit ? true : false}
        // />
      }
      {/* <FloatingInput
          label="Address 1"
          name="address1"
          hookForm
          register={register}
        /> */}

      {/* <div className="grid xl:grid-cols-2 xl:gap-6"> */}
      {
        //  <FloatingSelect
        //   label="Role"
        //   name="role"
        //   hookForm
        //   register={register}
        //   error={errors["role"]}
        //   options={[
        //     { value: "lead", label: "Manager (Admin)" },
        //     { value: "default", label: "Employee (Editor)" },
        //   ]}
        //   control={control}
        //   required
        // />
        // <BooleanType
        //   label="Receives email notifications?"
        //   className="mb-[20px]"
        //   name="notification"
        //   error={errors["notification"]}
        //   hookForm
        //   // data={{ defaultValue: 0 }}
        //   register={register}
        // />
        // <BooleanType
        //   label="Active"
        //   className="mb-[20px]"
        //   name="status"
        //   error={errors["status"]}
        //   hookForm
        //   // data={{ defaultValue: 0 }}
        //   register={register}
        //   description={`Disabling this field will block the user from viewing or editing your company's account or its content.`}
        // />
      }
      {/* </div> */}
      <Button type="submit" red>
        {edit ? "Save Changes" : "Add User"}
      </Button>
    </form>
  )
}

export default ManageUserForm
