import React, { useContext, useState } from "react"
import { Table, Tr, Td } from "./TableElements"
import ButtonEdit from "../Elements/ButtonEdit"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
// import ButtonDelete from "../Elements/ButtonDelete"
import MetaMini from "../Elements/MetaMini"
import CircleAvatar from "../Elements/CircleAvatar"
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid"
export default function ManageUserTable({
  data,
  // onDeleteClick,
  onEditClick,
  company,
}) {

  // alert(company.value)
  const { authentication } = useContext(AuthenticationContext)
  const { currentUserData } = authentication
  const { i } = currentUserData
  const [sortedData, setSortedData] = useState(data)
  const [sortCreated, setSortCreated] = useState("ascending")
  const [sortAccessed, setSortAccessed] = useState("ascending")
  const compareCreatedDates = (a, b) => {
    if (a.created === "never") return 1
    if (b.created === "never") return -1
    const dateA = new Date(
      parseInt(a.created.substring(6, 10)), // Year
      parseInt(a.created.substring(3, 5)) - 1, // Month (zero-based)
      parseInt(a.created.substring(0, 2)), // Day
      parseInt(a.created.substring(11, 13)), // Hours
      parseInt(a.created.substring(14, 16)), // Minutes
      parseInt(a.created.substring(17, 19))
    ) // Seconds))
    const dateB = new Date(
      parseInt(b.created.substring(6, 10)), // Year
      parseInt(b.created.substring(3, 5)) - 1, // Month (zero-based)
      parseInt(b.created.substring(0, 2)), // Day
      parseInt(b.created.substring(11, 13)), // Hours
      parseInt(b.created.substring(14, 16)), // Minutes
      parseInt(b.created.substring(17, 19))
    )
    console.log({ sortCreated })
    if (sortCreated === "ascending") {
      return dateB - dateA
    } else if (sortCreated === "descending") {
      return dateA - dateB
    }
  }
  const compareAccessDates = (a, b) => {
    if (a.last_access === "never") return 1
    if (b.last_access === "never") return -1
    const dateA = new Date(
      parseInt(a.last_access.substring(6, 10)), // Year
      parseInt(a.last_access.substring(3, 5)) - 1, // Month (zero-based)
      parseInt(a.last_access.substring(0, 2)), // Day
      parseInt(a.last_access.substring(11, 13)), // Hours
      parseInt(a.last_access.substring(14, 16)), // Minutes
      parseInt(a.last_access.substring(17, 19))
    ) // Seconds))
    const dateB = new Date(
      parseInt(b.last_access.substring(6, 10)), // Year
      parseInt(b.last_access.substring(3, 5)) - 1, // Month (zero-based)
      parseInt(b.last_access.substring(0, 2)), // Day
      parseInt(b.last_access.substring(11, 13)), // Hours
      parseInt(b.last_access.substring(14, 16)), // Minutes
      parseInt(b.last_access.substring(17, 19))
    )
    if (sortAccessed === "ascending") {
      return dateB - dateA
    } else if (sortAccessed === "descending") {
      return dateA - dateB
    }
  }
  console.log({ sortedData })
  return (
    <Table
      columns={[
        " ",
        // "User ID",
        "First Name",
        "Last Name",
        "Job Title",
        "Email Address",
        "Access Level",
        "Status",
        <div
          className="flex gap-2 cursor-pointer"
          onClick={() => {
            if (sortCreated === "ascending") {
              setSortCreated("descending")
              setSortedData(data.sort(compareCreatedDates))
            } else if (sortCreated === "descending") {
              setSortCreated("ascending")
              setSortedData(data.sort(compareCreatedDates))
            }
          }}
        >
          <span>Created</span>
          {sortCreated === "ascending" ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>,
        <div
          className="flex gap-2 cursor-pointer"
          onClick={() => {
            if (sortAccessed === "ascending") {
              setSortAccessed("descending")
              setSortedData(data.sort(compareAccessDates))
            } else if (sortAccessed === "descending") {
              setSortAccessed("ascending")
              setSortedData(data.sort(compareAccessDates))
            }
          }}
        >
          <span>Last Access</span>
          {sortAccessed === "ascending" ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>,
        "Assets Ordered",
        "Assets Downloaded",
        "Action",
      ]}
    >
      {data.map((person, k) => {
        return (
          <Tr key={k}>
            <Td className="flex gap-3 items-center">
              <CircleAvatar border sm full image={person.avatar} profile alt={person.first_name + ' ' + person.last_name} />
              {parseInt(i) === parseInt(person.id) && <MetaMini title="Me" />}
            </Td>
            {/* <Td>
              <div className="flex items-center">{k + 1}</div>
            </Td> */}
            <Td>
              <div className="text-dark">{person.first_name} </div>
            </Td>
            <Td>
              <div className="text-dark">{person.last_name} </div>
            </Td>
            <Td>
              <span className="capitalize">{person.job_title}</span>
            </Td>
            <Td>{person.email}</Td>
            <Td>
              <span>{person.role === "lead" ? "Manager" : "Associate"}</span>
            </Td>
            <Td>
              <span className="capitalize">{person.companies.find(f => parseInt(f.company_id) === parseInt(company.value)).status}</span>
            </Td>
            <Td>
              <span className="capitalize">{person.created}</span>
            </Td>
            <Td>
              <span className="capitalize">
                {person.last_access ? person.last_access : "Never"}
              </span>
            </Td>
            <Td>
              <span>{person.total_orders}</span>
            </Td>
            <Td>
              <span>{person.total_orders_digital}</span>
            </Td>
            <Td>
              <div className="flex space-x-6">
                {parseInt(i) === parseInt(person.id) ? (
                  <>
                    <ButtonEdit
                      text="Edit"
                      // onClick={() => onDeleteClick(person.id)}
                      disable
                    />
                    {/* <ButtonDelete text="Delete" disable /> */}
                  </>
                ) : (
                  <>
                    <ButtonEdit
                      text="Edit"
                      onClick={() => onEditClick(person.id)}
                    />
                    {/* <ButtonDelete
                      text="Delete"
                      onClick={() => onDeleteClick(person.id)}
                    /> */}
                  </>
                )}
              </div>
            </Td>
          </Tr>
        )
      })}
    </Table>
  )
}
